import React, { Children, cloneElement, useState, useEffect } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Recaptcha } from '@components'

const RecaptchaFormTokenGenerator = ({ action, children }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [recaptchaToken, setRecaptchaToken] = useState('')

  const refreshToken = async () => setRecaptchaToken(await executeRecaptcha(action || 'submit'))

  useEffect(() => {
    refreshToken && refreshToken()
  }, [executeRecaptcha])

  return Children.map(children, (element) => cloneElement(element, { recaptchaToken, refreshToken }))
}

export const RecaptchaForm = ({ children }) => (
  <Recaptcha>
    <RecaptchaFormTokenGenerator>{children}</RecaptchaFormTokenGenerator>
  </Recaptcha>
)

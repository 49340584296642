import React from 'react'

import './button.style.css'

export const Button = (props) => {
  const { children } = props

  return (
    <button className="acertis-ui-button-component" {...props}>
      {children}
    </button>
  )
}

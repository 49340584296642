import React from 'react'
import './selecionar-produto.css'
import produtos from '../../../produtos'
import { Produto, Titulo } from '../../components'

export function SelecionarProdutoScreen() {
  return (
    <div className="selecionar-produto">
      <Titulo>Selecione a opção desejada:</Titulo>

      <div className="row">
        {produtos.map(({ titulo, descricao, link, imagem }, key) => (
          <Produto key={key} titulo={titulo} descricao={descricao} imagem={imagem} link={link} />
        ))}
      </div>
    </div>
  )
}

import React, { useState, useEffect, useCallback } from 'react'
import { LoadingRing, Erro, Sucesso, InputPassword, RecaptchaForm } from '@components'
import { Button } from '..'

import { ApiAcfazPublicaService } from '@services'

import './solicitar-revogacao-form.css'

const Formulario = ({ recaptchaToken, refreshToken }) => {
  const [idSolicitacao, setIdSolicitacao] = useState('')
  const [senhaRevogacao, setSenhaRevogacao] = useState('')
  const [motivoRevogacao, setMotivoRevogacao] = useState('')
  const [opcoes, setOpcoes] = React.useState([])
  const [loadingResposta, setLoadingResposta] = useState({ loading: false })
  const service = new ApiAcfazPublicaService()
  const populaMotivo = useCallback(async () => {
    setOpcoes(await service.listaMotivo())
  }, [])

  useEffect(() => {
    populaMotivo()
  }, [populaMotivo])

  const entregaRevogacao = useCallback(async () => {
    setLoadingResposta({ loading: true })
    await service
      .postRevogacao({
        idSolicitacao,
        motivoRevogacao,
        senhaRevogacao,
        recaptchaToken,
      })
      .then(setSucesso)
      .catch(setErro)
    await refreshToken()
  }, [idSolicitacao, senhaRevogacao, motivoRevogacao, recaptchaToken])

  function onChangeIdSolicitacao(event) {
    setIdSolicitacao(event.target.value)
  }

  function onChangeSenhaSolicitacao(event) {
    setSenhaRevogacao(event.target.value)
  }

  function onChangeMotivoSolicitacao(event) {
    setMotivoRevogacao(event.target.value)
  }

  function setSucesso(erro) {
    setLoadingResposta({
      loading: false,
      sucesso: true,
      mensagem: erro?.response?.data?.message,
    })
  }

  function setErro(sucesso) {
    setLoadingResposta({
      loading: false,
      erro: true,
      mensagem: sucesso?.response?.data?.message,
    })
  }

  return (
    <div className="solicitar-revogacao-form-container">
      <h4>SOLICITAR REVOGAÇÃO</h4>
      <span>Informe os dados sem pontuação ou espaços.</span>
      <form>
        <label>Código da solicitação:</label>
        <input type="text" value={idSolicitacao} onChange={onChangeIdSolicitacao} />

        <label>Motivo:</label>
        <select onChange={onChangeMotivoSolicitacao} defaultValue="">
          <option className="placeholder-select-option" value="" disabled>
            Selecione...
          </option>
          {opcoes.map((opcao) => (
            <option key={opcao.valor} value={opcao.valor}>
              {opcao.descricao}
            </option>
          ))}
        </select>

        <InputPassword value={senhaRevogacao} label="Senha revogação:" onChange={onChangeSenhaSolicitacao} />
        <Button onClick={entregaRevogacao} type="button" disabled={loadingResposta?.loading}>
          confirmar revogação
        </Button>

        {loadingResposta?.loading && <LoadingRing title="Aguardando revogação..." />}

        {loadingResposta?.erro && (
          <Erro
            title="Ah não!"
            erro={loadingResposta?.mensagem || 'Ops! tivemos um problema na revogação. Tente novamente.'}
          />
        )}

        {loadingResposta?.sucesso && (
          <Sucesso title="Tudo certo!" sucesso={loadingResposta?.mensagem || 'Certificado revogado.'} />
        )}
      </form>
    </div>
  )
}

export const SolicitarRevogacaoForm = () => (
  <RecaptchaForm>
    <Formulario />
  </RecaptchaForm>
)

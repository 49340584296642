import React from 'react'
import { AutoridadeRegistros } from './autoridade-registros/autoridade-registros-tabela'
import { Titulo, RepositorioCollapsible, RepositorioOpcao } from '@components'
import './repositorio.css'
import ENV from '@environments'

const LinkContainer = ({ titulo, children }) => (
  <div>
    {titulo && <p className="ac-digital-titulo">{titulo}</p>}
    {children}
  </div>
)

const Arquivo = ({ arquivo }) => (
  <RepositorioOpcao url={arquivo?.caminho} type="document">
    {arquivo?.titulo}
  </RepositorioOpcao>
)

const Grupo = ({ grupo }) => (
  <LinkContainer titulo={grupo.titulo}>
    {grupo.arquivos.map((arquivo) => (
      <Arquivo key={arquivo.titulo} arquivo={arquivo} />
    ))}
  </LinkContainer>
)

const AcContainer = ({ ac, type }) => (
  <RepositorioCollapsible title={ac?.titulo}>
    <div className="ac-digital-container">
      {ac?.grupos.map((grupo) => (
        <Grupo key={grupo?.titulo} grupo={grupo} />
      ))}

      {type === 'ac2' && (
        <LinkContainer titulo="Autoridades de Registro">
          <AutoridadeRegistros />
        </LinkContainer>
      )}
    </div>
  </RepositorioCollapsible>
)

const PrestadoresDeServicoESuporte = ({ instituicao }) => (
  <li>
    <p>{instituicao}</p>
  </li>
)

const PssContainer = ({ pss }) => (
  <RepositorioCollapsible title={pss?.titulo}>
    <div className="prestador-servico-suporte">
      <ul className="prestador-servico-suporte-opcoes">
        {pss?.instituicoes.map((instituicao) => (
          <PrestadoresDeServicoESuporte key={instituicao} instituicao={instituicao} />
        ))}
      </ul>
    </div>
  </RepositorioCollapsible>
)

const Repositorio = ({ repositorio }) => (
  <>
    <AcContainer ac={repositorio?.ac1} />

    {repositorio?.ac2.map((ac2) => (
      <AcContainer key={ac2?.titulo} ac={ac2} type="ac2" />
    ))}

    <PssContainer pss={repositorio?.pss} />
  </>
)

export const RepositorioScreen = () => {
  return (
    <div className="repositorio-container">
      <Titulo>Repositório</Titulo>
      <div className="repositorio-content-container">
        <Repositorio repositorio={ENV.REPOSITORIO} />
      </div>
    </div>
  )
}

import ENV from '@environments'
import axios from 'axios'

const findRoute = (apiName) => ENV.BACKEND_ROUTES?.find((route) => route.route === apiName)?.url
const TOKEN = 'token'
export class TokenGerenciador {
  getToken() {
    try {
      return JSON.parse(window.sessionStorage.getItem(TOKEN))
    } catch (error) {
      /* eslint-env node */
    }
  }
  setToken(token) {
    window.sessionStorage.setItem(TOKEN, JSON.stringify(token))
  }
  removeToken() {
    window.sessionStorage.removeItem(TOKEN)
  }
  toSeconds() {
    return new Date().getTime() / 1000
  }
  async gerarToken(data) {
    if (this.toSeconds() >= this.getToken()?.dateExpires) {
      this.removeToken()
    }
    if (!this.getToken()) {
      const api = axios.create({
        baseURL: findRoute('acfaz-publica') + '/acfaz/login/oauth2/token',
      })
      const response = await api.post('', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      const token = response?.data
      //dateExpires em segundos
      token.dateExpires = this.toSeconds() + response.data.expires_in
      this.setToken(response?.data)
      return response.data
    }
    return Promise.resolve(this.getToken())
  }
  getParamsRefresh() {
    return {
      grant_type: ENV?.TOKEN?.grant_type_refresh,
      refresh_token: this.getToken()?.access_token,
      username: ENV?.TOKEN?.username,
      password: ENV?.TOKEN?.password,
    }
  }
  getParamsBasic() {
    return {
      grant_type: ENV?.TOKEN?.grant_type_basic,
      authenticated_userid: ENV?.TOKEN?.username,
      provision_key: ENV?.TOKEN?.password,
      client_id: ENV?.TOKEN?.clientId,
      client_secret: ENV?.TOKEN?.clientSecret,
    }
  }
}

import React from 'react'
import { Linux, Windows } from '@assets'
export const softwares = [
  {
    nome: 'Emissor de certificado A1 Windows x64',
    icon: <Windows className="icon" />,
    iconDescricao: 'Windows',
    link: 'https://downloads.acdigital.com.br/emissor/emissor.exe',
    descricao:
      'Emissor de certificado para o sistema Windows x64. Acompanha OpenJDK, drivers para gerenciadores de token e cartões inteligentes',
  },
  {
    nome: 'Emissor de certificado A1 Linux',
    icon: <Linux className="icon" />,
    link: 'https://downloads.acdigital.com.br/emissor/emissor.AppImage',
    iconDescricao: 'Linux',
    descricao: 'Emissor de certificado para sistemas Linux. Acompanha OpenJDK',
  },
]

import React from 'react'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import './inputpassword.css'
export const InputPassword = (props) => {
  const [show, setShow] = React.useState(false)
  return (
    <div className="input-password">
      <label>{props.label}</label>
      <input type={show ? 'text' : 'password'} value={props.value} onChange={props.onChange} />
      {!show ? (
        <VisibilityIcon className="icon-password" onClick={() => setShow(!show)} />
      ) : (
        <VisibilityOffIcon onClick={() => setShow(!show)} className="icon-password" />
      )}
    </div>
  )
}

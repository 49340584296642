import React from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  SelecionarProdutoScreen,
  RevogarCertificadoScreen,
  RepositorioScreen,
  TestarCertificadoScreen,
  SoftWaresScreen,
} from './ui/screens'

const routes = [
  {
    component: SelecionarProdutoScreen,
    path: '/',
    title: 'Seleção de Produtos',
    exact: true,
  },
  {
    component: RevogarCertificadoScreen,
    path: '/revogar-certificado',
    title: 'Revogar Certificado',
  },
  { component: RepositorioScreen, path: '/repositorio', title: 'Repositório' },
  {
    component: TestarCertificadoScreen,
    path: '/testar-certificado',
    title: 'Testar Certificado',
  },
  {
    component: SoftWaresScreen,
    path: '/download/softwares',
    title: 'Seja bem-vindo realize download do nosso emissor de certificado.',
  },
]

export function Routes() {
  return (
    <Switch>
      {routes.map((route, key) => (
        <Route
          key={key}
          path={route.path}
          render={(props) => {
            const Component = route.component
            return <Component title={route.title} {...props} />
          }}
          exact={route.exact}
        />
      ))}
    </Switch>
  )
}

import React from 'react'
import { Titulo, SolicitarRevogacaoForm } from '../../components'

import './revogar-certificado.css'

export const RevogarCertificadoScreen = () => {
  return (
    <div className="revogar-certificado-container">
      <Titulo>Revogação de certificado</Titulo>
      <div>
        <div className="lado-esquerdo">
          <div>
            <div>
              A revogação de um certificado Digital é definitiva. Uma vez efetuada, <b />
              não há a possibilidade de retornar o certificado ao status anterior.
            </div>
            <div>
              O titular do Certificado Digital pode solicitar a revogação a qualquer <b />
              tempo, podendo ser feito neste local mediante fornecimento dos dados <b />
              solicitados. Em caso de não possuir mais os dados, a revogação deverá <b />
              ocorrer de forma presencial com um Agente de Registro.
            </div>
          </div>
          <span>Principais motivos para revogar um Certificado:</span>
          <ul>
            <li>Comprometimento da mídia ou arquivo do Certificado</li>
            <li>Dados incorretos no Certificado</li>
            <li>Determinação da AC ou AR</li>
            <li>Esquecimento ou bloqueio de senha</li>
            <li>Falha na emissão do Certificado</li>
            <li>Necessidade de alteração de dados do Certificado</li>
            <li>Perda, roubo ou acesso indevido ao Certificado</li>
          </ul>
        </div>

        <div className="lado-direito">
          <SolicitarRevogacaoForm />
        </div>
      </div>
    </div>
  )
}

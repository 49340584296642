const env = {
  ENV: 'LABSEC',
  BACKEND_ROUTES: [
    {
      route: 'acfaz-teste-certificado',
      url: 'https://acfaz-certificate-test.acdigital.com.br',
    },
    {
      route: 'acfaz-publica',
      url: 'https://acfaz-api.acdigital.com.br',
    },
  ],
  GOOGLE_RECAPTCHA_SITE_KEY: '6LdZDN4aAAAAANrlm_bbZ8atYkS6J_gU51FLcvgX',
  REPOSITORIO: {
    ac1: {
      titulo: 'AC DIGITAL MAIS',
      grupos: [
        {
          arquivos: [
            {
              titulo: 'Declaração de Práticas de Certificação',
              caminho: 'https://repositorio.acdigital.com.br/docs/ac-digital-mais.pdf',
            },
            {
              titulo: 'Política de Segurança',
              caminho: 'https://repositorio.acdigital.com.br/docs/ps-ac-digital-mais.pdf',
            },
          ],
        },
        {
          titulo: 'Certificados da Raiz Brasileira v5',
          arquivos: [
            {
              titulo: 'Certificado da AC Raiz Brasileira - CER',
              caminho: 'http://repositorio.acdigital.com.br/cert/ac-raiz-v5.cer',
            },
            {
              titulo: 'Certificado da AC Raiz Brasileira - CRT',
              caminho: 'http://repositorio.acdigital.com.br/cert/ac-raiz-v5.crt',
            },
          ],
        },
        {
          titulo: 'Certificados da Autoridade Certificadora',
          arquivos: [
            {
              titulo: 'Certificado da AC Digital Mais - CER',
              caminho: 'http://repositorio.acdigital.com.br/cert/ac-digital-mais.cer',
            },
            {
              titulo: 'Certificado da AC Digital Mais - CRT',
              caminho: 'http://repositorio.acdigital.com.br/cert/ac-digital-mais.crt',
            },
          ],
        },
        {
          titulo: 'Cadeia de Certificação da Autoridade Certificadora',
          arquivos: [
            {
              titulo: 'Cadeia de Certificação da AC Digital Mais - P7B',
              caminho: 'http://repositorio.acdigital.com.br/cert/ac-digital-mais.p7b',
            },
            {
              titulo: 'Cadeia de Certificação da AC Digital Mais - P7B DER',
              caminho: 'http://repositorio.acdigital.com.br/cert/ac-digital-mais.p7b.der',
            },
            {
              titulo: 'Cadeia de Certificação da AC Digital Mais - P7B PEM',
              caminho: 'http://repositorio.acdigital.com.br/cert/ac-digital-mais.p7b.pem',
            },
          ],
        },
        {
          titulo: 'Certificados Revogados',
          arquivos: [
            {
              titulo: 'Lista de Certificados Revogados',
              caminho: 'http://repositorio.acdigital.com.br/lcr/ac-digital-mais.crl',
            },
            {
              titulo: 'Lista de Certificados Revogados 2',
              caminho: 'http://repositorio2.acdigital.com.br/lcr/ac-digital-mais.crl',
            },
          ],
        },
      ],
    },
    ac2: [
      {
        titulo: 'AC DIGITAL MÚLTIPLA',
        grupos: [
          {
            arquivos: [
              {
                titulo: 'Declaração de Práticas de Certificação',
                caminho: 'https://repositorio.acdigital.com.br/docs/ac-digital-multipla.pdf',
              },
              {
                titulo: 'Política de Segurança',
                caminho: 'https://repositorio.acdigital.com.br/docs/ps-ac-digital-multipla.pdf',
              },
            ],
          },
          {
            titulo: 'Certificados da Autoridade Certificadora',
            arquivos: [
              {
                titulo: 'Certificado da AC Digital Múltipla - CER',
                caminho: 'http://repositorio.acdigital.com.br/cert/ac-digital-multipla-g1.cer',
              },
              {
                titulo: 'Certificado da AC Digital Múltipla - CRT',
                caminho: 'http://repositorio.acdigital.com.br/cert/ac-digital-multipla-g1.crt',
              },
            ],
          },
          {
            titulo: 'Cadeia de Certificação da Autoridade Certificadora',
            arquivos: [
              {
                titulo: 'Cadeia de Certificação da AC Digital Múltipla - P7B',
                caminho: 'http://repositorio.acdigital.com.br/cert/ac-digital-multipla-g1.p7b',
              },
              {
                titulo: 'Cadeia de Certificação da AC Digital Múltipla - P7B DER',
                caminho: 'http://repositorio.acdigital.com.br/cert/ac-digital-multipla-g1.p7b.der',
              },
              {
                titulo: 'Cadeia de Certificação da AC Digital Múltipla - P7B PEM',
                caminho: 'http://repositorio.acdigital.com.br/cert/ac-digital-multipla-g1.p7b.pem',
              },
            ],
          },
          {
            titulo: 'Política de Certificado',
            arquivos: [
              {
                titulo: 'PC A1',
                caminho: 'https://repositorio.acdigital.com.br/docs/pc-a1-ac-digital-multipla.pdf',
              },
              {
                titulo: 'PC A3',
                caminho: 'https://repositorio.acdigital.com.br/docs/pc-a3-ac-digital-multipla.pdf',
              },
            ],
          },
          {
            titulo: 'Certificados Revogados',
            arquivos: [
              {
                titulo: 'Lista de Certificados Revogados',
                caminho: 'http://repositorio.acdigital.com.br/lcr/ac-digital-multipla-g1.crl',
              },
              {
                titulo: 'Lista de Certificados Revogados 2',
                caminho: 'http://repositorio2.acdigital.com.br/lcr/ac-digital-multipla-g1.crl',
              },
            ],
          },
        ],
      },
    ],
    pss: {
      titulo: 'PRESTADORES DE SERVIÇO E SUPORTE',
      instituicoes: [
        'COMPANHIA DE TECNOLOGIA DA INFORMAÇÃO E COMUNICAÇÃO DO PARANÁ',
        'FUNDAÇÃO DE ENSINO E ENGENHARIA DE SANTA CATARINA',
        'GRIAULE LTDA',
        'UNIVERSIDADE FEDERAL DE SANTA CATARINA',
      ],
    },
  },
  TOKEN: {
    //TODO:  Verificar onde isso irá ficar (variáveis de ambiente)
    clientId: 'Cw8LGX7cWMppaMKq3caQ5WAfKdJhdZxG',
    clientSecret: 'uFwXQnfnC9KEBV26h9eY9Fc6T7myRY84vSv3F8TUC4xfNcNTb5UtVnp9qZQYPZL6FZx94fBCTtEbVQFjxXtuPZ3c4UC8neyU',
    username: 'repositorio',
    password: '0paUoAy4S8gpIcPIajEY2g0nW3R0YNmn',
    grant_type_basic: 'password',
    grant_type_refresh: 'refresh',
  },
}

export default env

import React from 'react'
import { ImagemSucesso } from '@assets'
import './sucesso.css'
export const Sucesso = (props) => {
  return (
    <div className="sucesso-header">
      <div className="sucesso-content">
        <ImagemSucesso />
        <p className="titulo">{props.title}</p>
        <span>{props.sucesso}</span>
      </div>
    </div>
  )
}

import React from 'react'
import { useAutoridadeRegistros } from './useAutoridadeRegistros'
import { SetaDireita, SetaEsquerda } from '@assets'
import { RepositorioCollapsible } from '@components'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import StringMask from 'string-mask'
import './autoridade.registro.css'

const Cep = ({ objeto, format }) => (
  <p>CEP: {new StringMask(format).apply(objeto?.endereco?.cep?.replace(/\D/g, ''))}</p>
)
const Telefone = ({ objeto, format }) => (
  <p>{new StringMask(format).apply(objeto?.contato?.telefone?.replace(/\D/g, ''))}</p>
)

const ContentAR = (props) => {
  const { objeto } = props
  return (
    <div className="endereco-ar">
      <p>{objeto?.endereco?.logradouro + ', ' + objeto?.endereco?.numero + ' ' + objeto.endereco?.complemento}</p>
      <Cep format="#####-###" {...props} />
      <p>
        {objeto?.endereco?.bairro}-{objeto?.endereco?.cidade}/{objeto.endereco?.uf}
      </p>
      <Telefone format="(##) ####-####" {...props} />
    </div>
  )
}

export const AutoridadeRegistros = (props) => {
  function testeInativada(objeto) {
    if (objeto?.status === 'INATIVO') {
      return objeto?.nomeITI + ' DESCREDENCIADA' + (objeto?.dataInativacao ? ' - ' + objeto.dataInativacao : ' ')
    }
    return objeto?.nomeITI
  }

  const {
    proximaPaginaAutoridades,
    voltarPaginaAutoridades,
    valorFinal,
    lista,
    valorInicial,
  } = useAutoridadeRegistros({ quantidadePorPagina: 10 })

  return (
    <div>
      {lista.map((ar, index) => (
        <RepositorioCollapsible compact key={valorInicial + index} title={testeInativada(ar)} icon={ExpandMoreIcon}>
          <ContentAR objeto={ar} />
        </RepositorioCollapsible>
      ))}
      <div className="prestador-index">
        <p>
          {valorInicial} - {valorFinal}
        </p>
        <div>
          <SetaEsquerda onClick={voltarPaginaAutoridades} />
          <SetaDireita onClick={proximaPaginaAutoridades} />
        </div>
      </div>
    </div>
  )
}

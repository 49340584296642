import React, { useState, useCallback } from 'react'
import { Button } from '..'
import { useHistory } from 'react-router-dom'
import { JsonUtils } from '@services'
import ImagemSucesso from '../../assets/images/sucesso.png'
import ImagemErro from '../../assets/images/erro.png'
import _ from 'lodash'
import moment from 'moment'

import './testar-certificado-resultado.css'
// TODO Verificar tamanhos, core e fonte dos textos

export const TestarCertificadoResultado = () => {
  const history = useHistory()
  const VALIDADE = 'validade'
  const dadosCertificado = history?.location?.state?.dados
  const jsonUtilsService = new JsonUtils()
  const [sucesso] = useState(!!dadosCertificado)
  const [layouts, setLayouts] = useState([])
  const chaves = jsonUtilsService.obterChaves(dadosCertificado)
  const getLayouts = useCallback(() => {
    //Cada campo tem um seção
    //Isso é útil, visto que podemos utilizar apenas uma exibição para qualquer certificado
    const secoes = ['IDENTIFICAÇÃO DO CERTIFICADO', 'DADOS DO TITULAR', 'DETALHES TÉCNICOS']
    if (layouts.length === 0) {
      setLayouts(
        secoes.map((titulo, i) => ({
          titulo,
          filtro: chaves.filter((value) => value.secao === i + 1),
        })),
      )
    }
  }, [chaves, layouts])
  React.useEffect(() => {
    getLayouts()
  }, [getLayouts])
  const Sucesso = () => {
    return (
      <div className="testar-certificado-resultado-header">
        <img src={ImagemSucesso} alt="Sucesso" />
        <div>
          <p className="titulo-sucesso">Teste realizado com sucesso.</p>
        </div>
      </div>
    )
  }
  function parseDate(stringInicio, stringDestino, position) {
    return stringInicio.slice(0, position) + stringDestino + stringInicio.slice(position)
  }
  const validadeCertificado = ({ valor, validade }) => (
    <div className="validade-certificado">
      {validade ? (
        <>
          <span>{valor}</span>
          <div className="validade-certificado-img">
            <span>Certificado válido</span>
            <img src={ImagemSucesso} alt="Certificado válido" />
          </div>
        </>
      ) : (
        <>
          <span>{valor}</span>
          <div className="validade-certificado-img">
            <span>Certificado não válido</span>
            <img src={ImagemErro} alt="Certificado não válido" />
          </div>
        </>
      )}
    </div>
  )
  function formatDate(data) {
    if (!moment(data).isValid()) {
      data = parseDate(data, '/', 2)
      data = parseDate(data, '/', 5)
      return data
    } else {
      return moment(data).format('DD/MM/YYYY')
    }
  }
  const Erro = () => {
    return (
      <div className="testar-certificado-resultado-header">
        <img src={ImagemErro} alt="erro" />
        <div>
          <p className="titulo-erro">Não foi possível finalizar o teste deste certificado.</p>
          <span>Entre em contato com nosso suporte para maiores informações.</span>
        </div>
      </div>
    )
  }
  const subComponentDados = (value) => {
    const valor = _.get(dadosCertificado, value.chave)
    return (
      !!valor &&
      (Array.isArray(valor) ? (
        <div key={value.chave}>
          <p className="opcao-section">{value.label}</p>
          {valor.map((vl, iv) => (
            <span key={iv} className="valor-opcao-section">
              {vl}
            </span>
          ))}
        </div>
      ) : (
        <div key={value.chave}>
          <p className="opcao-section">{value.label}</p>
          {value.chave === VALIDADE ? (
            validadeCertificado({
              valor,
              validade: _.get(dadosCertificado, 'valid'),
            })
          ) : (
            <span className="valor-opcao-section">
              {value.label?.toLowerCase().includes('data') ? formatDate(valor) : valor}
            </span>
          )}
        </div>
      ))
    )
  }
  const DadosSection = () => {
    return (
      !!layouts?.length &&
      layouts.map((dados) => {
        return (
          <div key={dados.titulo} className="testar-certificado-resultado-section">
            <h4 className="titulo-section">{dados.titulo}</h4>
            <div className="wrapper-section">
              <div className="metade-section">
                {!!dados?.filtro &&
                  dados.filtro.map((value, index) => {
                    return subComponentDados(value)
                  })}
              </div>
            </div>
          </div>
        )
      })
    )
  }

  return (
    <div className="testar-certificado-resultado">
      <div>
        {sucesso ? (
          <>
            <Sucesso />
            <DadosSection />
          </>
        ) : (
          <Erro />
        )}
      </div>
      <Button style={{ marginTop: 30 }} onClick={history.goBack}>
        voltar
      </Button>
    </div>
  )
}

import { useState, useEffect, useCallback } from 'react'
import { ApiAcfazPublicaService } from '@services'
export function useAutoridadeRegistros(props) {
  const { quantidadePorPagina } = props
  const [lista, setLista] = useState([]),
    valorFinal = lista?.pageable?.offset + quantidadePorPagina || 0,
    valorInicial = lista?.pageable?.offset + 1 || 0
  const [page, setPagina] = useState(0)
  const buscarDadosAutoridades = useCallback(
    async ({ page: pageTarget }) => {
      const service = new ApiAcfazPublicaService()
      service
        .buscarAutoridadesRegistros({
          page: pageTarget,
          size: quantidadePorPagina,
        })
        .then((response) => {
          setPagina(response?.pageable?.pageNumber)
          setLista(response)
        })
    },
    [quantidadePorPagina],
  )
  useEffect(() => {
    buscarDadosAutoridades({ page, size: quantidadePorPagina })
  }, [buscarDadosAutoridades, page, quantidadePorPagina])

  const proximaPaginaAutoridades = () => {
    if (lista.totalPages - 1 > page) {
      buscarDadosAutoridades({ page: page + 1, size: 10 })
    }
  }

  const voltarPaginaAutoridades = () => {
    page >= 1 && buscarDadosAutoridades({ page: page - 1, size: quantidadePorPagina })
  }
  return {
    proximaPaginaAutoridades,
    voltarPaginaAutoridades,
    valorFinal,
    lista: lista.content || [],
    valorInicial,
  }
}

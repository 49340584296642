import React, { useState } from 'react'

import './repositorio-collapsible.css'

import ImagemSeta from '../../assets/images/seta-baixo-outline.svg'

function VisualFeedback({ icon, onClick }) {
  const Icon = icon

  return icon ? <Icon onClick={onClick} /> : <img onClick={onClick} alt="seta" src={ImagemSeta} />
}

export const RepositorioCollapsible = ({ children, title, icon, compact }) => {
  const [open, setOpen] = useState(null)

  function toggleOpen() {
    setOpen(!open)
  }

  function onClick() {
    toggleOpen()
  }

  function getOpenClassName() {
    if (open === true) return 'repositorio-collapsible--open'
    if (open === false) return 'repositorio-collapsible--closed'
  }

  function getDensityClassName() {
    return compact ? 'repositorio-collapsible--compact' : 'repositorio-collapsible--regular'
  }

  return (
    <div className={`repositorio-collapsible-container ${getOpenClassName()} ${getDensityClassName()}`}>
      <div className="repositorio-collapsible-wrapper">
        <VisualFeedback icon={icon} onClick={onClick} />
        <span>{title}</span>
      </div>
      <div className="repositorio-collapsible-content">{children}</div>
    </div>
  )
}

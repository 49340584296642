import React from 'react'

import Enviar from '../../assets/images/enviar.png'
import Documento from '../../assets/images/documento.png'

import './repositorio-opcao.css'

const DOCUMENT = 'document'

export const RepositorioOpcao = ({ children, type, url, icon }) => {
  const imagem = type === DOCUMENT ? Documento : Enviar
  const alt = type === DOCUMENT ? 'Documento' : 'Seta'

  return (
    <div className="repositorio-opcao-container">
      <a id="download-manual" href={url} target="_blank" download rel="noopener noreferrer">
        <div>
          <img src={icon || imagem} alt={alt} />
        </div>
        <p>{children}</p>
      </a>
    </div>
  )
}

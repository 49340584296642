import { BaseService } from '../base.service'

export class TesteCertificadoService extends BaseService {
  constructor(props) {
    super('acfaz-teste-certificado')
  }
  testar() {
    return super.get('/certificado/analise')
  }
}

import { BaseService } from '../base.service'

export class ApiAcfazPublicaService extends BaseService {
  constructor(props) {
    super('acfaz-publica')
  }
  async postRevogacao({ idSolicitacao, motivoRevogacao, senhaRevogacao, recaptchaToken }) {
    const revokeComments = ''
    const revokeDate = new Date().toJSON().slice(0, 10).replace(/-/g, '')
    const autenticacao = await this.getTokenApi()

    return super.post(
      '/acfaz/revogacao/publica/revogar',
      {
        idSolicitacao,
        revokeComments,
        revokeDate,
        reasonRevokeEnum: motivoRevogacao,
        revokePassw: senhaRevogacao,
      },
      {
        params: {
          recaptchaToken,
        },
        ...autenticacao,
      },
    )
  }

  async listaMotivo() {
    const autenticacao = await this.getTokenApi()
    return super.get('/acfaz/dominio/motivo/revogacao', autenticacao)
  }

  async buscarAutoridadesRegistros(params) {
    const autenticacao = await this.getTokenApi()
    return super.get('/acfaz/autoridade-registro/_', {
      params,
      ...autenticacao,
    })
  }

  async getTokenApi() {
    const response = await this.gerarAcessTokenBasic()
    return { headers: { Authorization: 'Bearer ' + response?.access_token } }
  }
}

import React from 'react'
import { SoftWares, Titulo, RepositorioOpcao } from '@components'
import manualPdf from '../../docs/Manual de Emissão do Certificado.pdf'
export function SoftWaresScreen(props) {
  return (
    <div>
      <Titulo>{props.title}</Titulo>
      <p>Olá!</p>
      <p>
        Nessa página você conseguirá baixar o nosso emissor, para emitir seu certificado digital após ter realizado o
        processo de validação com a aprovação do mesmo.
      </p>
      <p>
        É muito importante que as instruções a seguir sejam seguidas corretamente, assim é possível garantir a melhor
        experiência a você!
      </p>
      <p>
        1° – Realize a instalação do nosso emissor. Ele irá preparar seu computador para receber e utilizar nosso
        certificado digital
      </p>
      <p>2° – Com a conclusão da instalação, execute o nosso aplicativo no seu computador.</p>
      <p>3° - Tenha em mãos o usuário e senha de emissão para continuidade.</p>
      <p>Siga os passos indicados e em caso de dúvidas contate nosso suporte!</p>
      <RepositorioOpcao url={manualPdf} type="document">
        Manual detalhado para emissão do Certificado
      </RepositorioOpcao>
      <SoftWares />
    </div>
  )
}

export const labels = [
  {
    label: 'Tipo',
    secao: 1,
    busca: ['tipo'],
  },
  {
    label: 'Emissor',
    secao: 1,
    busca: ['emissor'],
  },
  {
    label: 'Número de série',
    secao: 1,
    busca: ['numeroSerie'],
  },
  {
    label: 'Dados do certificado',
    secao: 1,
    busca: ['listDN'],
  },
  {
    label: 'Titular',
    secao: 2,
    busca: ['titular'],
  },
  {
    label: 'E-mail',
    secao: 2,
    busca: ['email'],
  },
  {
    label: 'Cpf',
    secao: 2,
    busca: ['certificadoPF.cpf', 'certificadoPJ.cpfResponsavel'],
  },
  {
    label: 'Cnpj',
    secao: 2,
    busca: ['certificadoPJ.cnpj'],
  },
  {
    label: 'Data de nascimento',
    secao: 2,
    busca: ['certificadoPF.dataNascimento', 'certificadoPJ.dataNascimento'],
  },
  {
    label: 'Cei',
    secao: 2,
    busca: ['certificadoPF.cei', 'certificadoPJ.cei'],
  },
  {
    label: 'Nome Responsável',
    secao: 2,
    busca: ['certificadoPJ.nomeResponsavel', 'nome'],
  },
  {
    label: 'Nis',
    secao: 2,
    busca: ['certificadoPJ.nis', 'certificadoPF.nis'],
  },
  {
    label: 'Documento de identificação',
    secao: 2,
    busca: ['certificadoPJ.rg', 'certificadoPF.rg'],
  },
  {
    label: 'Orgão Emissor',
    secao: 2,
    busca: ['certificadoPJ.orgaoEmissor', 'certificadoPF.orgaoEmissor'],
  },
  {
    label: 'Estado Emissor',
    secao: 2,
    busca: ['certificadoPJ.orgaoEmissorUF', 'certificadoPF.orgaoEmissorUF'],
  },
  {
    label: 'Titulo Eleitor',
    secao: 2,
    busca: ['certificadoPF.tituloEleitor'],
  },
  {
    label: 'Cidade Eleitoral',
    secao: 2,
    busca: ['certificadoPF.cidadeEleitoral'],
  },
  {
    label: 'Seção Eleitoral',
    secao: 2,
    busca: ['certificadoPF.secaoEleitoral'],
  },
  {
    label: 'Zona Eleitoral',
    secao: 2,
    busca: ['certificadoPF.zonaEleitoral'],
  },
  {
    label: 'Estado emissor do título',
    secao: 2,
    busca: ['certificadoPF.zonaEleitoralUF'],
  },
  {
    label: 'Validade',
    secao: 3,
    busca: ['validade'],
  },
  {
    label: 'Lista de chaves',
    secao: 3,
    busca: ['listExtendedKeyUsage'],
  },
  {
    label: 'Id de política',
    secao: 3,
    busca: ['idPolitica'],
  },
  {
    label: 'Política',
    secao: 1,
    busca: ['politica'],
  },
  {
    label: 'Url Pc Dc',
    secao: 3,
    busca: ['urlPcDpc'],
  },
  {
    label: 'Algorítimo de assinatura',
    secao: 3,
    busca: ['signatureAlgorithm'],
  },
  {
    label: 'Algorítimo das chaves',
    secao: 3,
    busca: ['RSA'],
  },
  {
    label: 'Listas de revogação',
    secao: 3,
    busca: ['listLcr'],
  },
]

import _ from 'lodash'
import { labels } from '@constantes'
export class JsonUtils {
  Remove(str, startIndex, count) {
    return str.substr(0, startIndex) + str.substr(startIndex + count)
  }
  get(objeto, callback, key) {
    _.findKey(objeto, (v, k) => {
      if (typeof objeto[k] === 'object' && !_.isArray(objeto[k])) {
        this.get(objeto[k], callback, key + '.' + k)
      } else {
        callback(key + '.' + k, k)
      }
    })
  }
  obterChaves(objeto) {
    const s = []
    this.get(
      objeto,
      (k, label) => {
        k = this.Remove(k, 0, 1)
        let chave
        label = _.find(labels, (v) =>
          _.find(v.busca, (sv) => {
            if (sv === k) {
              chave = sv
              return sv
            }
          }),
        )
        if (label) {
          s.push({
            ...label,
            chave,
            nome: k,
          })
        }
      },
      '',
    )
    s.sort(function (a, b) {
      if (a.ordem < b.ordem) {
        return -1
      }
      return 0
    })
    return s
  }
}

import React from 'react'
import { softwares } from '@constantes'
import './software.style.css'

export function SoftWares() {
  return (
    <div className="row center-xs">
      {softwares.map((software) => {
        return (
          <div className="col-xs-12 col-sm-6 col-md-3" key={software.nome}>
            <a href={software.link} className="software">
              {software.icon}
              <p>{software.iconDescricao}</p>
              <div className="software__texto">
                <h3>{software.nome}</h3>
                <span className="descricao">{software.descricao}</span>
              </div>
            </a>
          </div>
        )
      })}
    </div>
  )
}

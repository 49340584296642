import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import ENV from '@environments'

export const Recaptcha = ({ children }) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={ENV.GOOGLE_RECAPTCHA_SITE_KEY}
      scriptProps={{ async: true, defer: true, appendTo: 'body' }}
    >
      {children}
    </GoogleReCaptchaProvider>
  )
}

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './produto.css'

export function Produto({ titulo, descricao, link, imagem }) {
  const [urlImagem, setUrlImagem] = useState(null)

  async function carregarImagem(imagem) {
    const resolve = await import(`../../assets/images/${imagem}`)
    setUrlImagem(resolve.default)
  }

  useEffect(() => {
    async function buscarDados() {
      await carregarImagem(imagem)
    }
    buscarDados()
  }, [])

  return (
    <div className="col-xs-12 col-sm-6 col-md-3">
      {urlImagem && (
        <Link to={link} className="produto">
          <img src={urlImagem} alt={`ícone representando a ação para ${imagem}`} />
          <div className="produto__texto">
            <h3>{titulo}</h3>
            <span className="descricao">{descricao}</span>
          </div>
        </Link>
      )}
    </div>
  )
}

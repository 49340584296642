import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import './App.css'
import { Routes } from './routes'

function App() {
  return (
    <div className="App">
      <header></header>

      <Router>
        <Routes />
      </Router>

      <footer></footer>
    </div>
  )
}

export default App

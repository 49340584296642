export default [
  {
    nome: 'Emissão de Certificado',
    link: 'download/softwares',
    titulo: 'Emitir',
    descricao: 'Baixe os principais softwares para emitir o seu certificado',
    imagem: 'emitir-certificado.png',
  },
  {
    nome: 'Teste de Certificado',
    link: 'testar-certificado',
    titulo: 'Testar',
    descricao: 'Certificado',
    imagem: 'testar-certificado.png',
  },
  {
    nome: 'Revogação de Certificado',
    link: 'revogar-certificado',
    titulo: 'Revogar',
    descricao: 'Certificado',
    imagem: 'revogar-certificado.png',
  },
  {
    nome: 'Repositório de Certificado',
    link: 'repositorio',
    titulo: null,
    descricao: 'Repositório',
    imagem: 'repositorio.png',
  },
]

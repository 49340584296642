import React from 'react'
import { ImagemErro } from '@assets'
import './erro.css'
export const Erro = (props) => {
  return (
    <div className="erro-header">
      <div className="erro-content">
        <ImagemErro />
        <p className="titulo-erro">{props.title}</p>
        <span>{props.erro}</span>
      </div>
    </div>
  )
}

import React from 'react'
import { Route } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { Titulo, TestarCertificadoResultado, Button } from '../../components'
import { TesteCertificadoService } from '@services'
import './testar-certificado.css'

// TODO fonte p e button

export const TestarCertificadoScreen = ({ match }) => {
  const history = useHistory()
  const testeCertificadoService = new TesteCertificadoService()
  const pathToInicio = match?.path
  const pathToResultado = `${match?.path}/resultado`

  const testeCertificado = async () => {
    const dados = await testeCertificadoService.testar()
    history.push(pathToResultado, { dados })
  }
  const TestarCertificadoInicio = () => {
    return (
      <>
        <p>
          Para testar seu Certificado Digital, é preciso que ele esteja correntamente instalado,
          <br />
          assim como as cadeias de certificação e demais drivers necessários.
        </p>
        <p>
          Tratando-se de Certificado A3 ou A4, é necessário que o driver do dispositivo esteja <br />
          devidamente instalado em seu equipamento.
        </p>
        <Button onClick={testeCertificado}>testar certificado</Button>
      </>
    )
  }

  return (
    <div className="testar-certificado-container">
      <Titulo>Teste seu Certificado Digital</Titulo>
      <Route exact path={pathToInicio} component={TestarCertificadoInicio} />
      <Route exact path={pathToResultado} component={TestarCertificadoResultado} />
    </div>
  )
}
